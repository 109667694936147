import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Placeholder from '../assets/placeholder.png';
import AltaBank from '../assets/ads/altabank.jpg';
import Lexus from '../assets/ads/LHMiller-Lexus.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "deer-valleysupsup-music-festival",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#deer-valleysupsup-music-festival",
        "aria-label": "deer valleysupsup music festival permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deer Valley`}<sup>{`®`}</sup>{` Music Festival`}</h1>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <h2 {...{
      "id": "digital-publications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#digital-publications",
        "aria-label": "digital publications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Digital Publications`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publication.`}</p>
    </blockquote>
    {
      /****************** PUBLICATIONS ******************/
    }
    {
      /****************** PUBLICATIONS ******************/
    }
    {
      /****************** PUBLICATIONS ******************/
    }
    <section className="home-list">
      <Link to="/dvmf-2023" alt="Link to Deer Valley® Music Festival 2022 publication." mdxType="Link">
        <h3 {...{
          "id": "deer-valley-music-festival--2023",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#deer-valley-music-festival--2023",
            "aria-label": "deer valley music festival  2023 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Deer Valley® Music Festival | 2023`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "152.91666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAADAv/aAAwDAQACEAMQAAABkYjObqHhncbE6CEP/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAECEQMSEP/aAAgBAQABBQKeUuHyg859wVirT1IqFaRVa//EABYRAQEBAAAAAAAAAAAAAAAAAAASAf/aAAgBAwEBPwGUr1ev/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AciP/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQETEgIUH/2gAIAQEABj8CkkLmNKn0W//EAB0QAAICAgMBAAAAAAAAAAAAAAABESExcRBBYeH/2gAIAQEAAT8hiMU9iKq2/o2Zp5RA1we1c9CLThZjo3HrZaNU5JFyf//aAAwDAQACAAMAAAAQmz3P/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARUf/aAAgBAwEBPxA2g0X/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPxCq0h//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUGREIHR/9oACAEBAAE/EGD4YFyTRVxgCwsfuDKFKabO/Fb2wsSmXTnCCNoMsvaJOugRAvVoqZFAoF6jKX4FJ//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1f530db5040b6a49412a85b74e036f6e/8ac56/dvmf2023-cover.webp 240w", "/static/1f530db5040b6a49412a85b74e036f6e/d3be9/dvmf2023-cover.webp 480w", "/static/1f530db5040b6a49412a85b74e036f6e/87ca7/dvmf2023-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1f530db5040b6a49412a85b74e036f6e/09b79/dvmf2023-cover.jpg 240w", "/static/1f530db5040b6a49412a85b74e036f6e/7cc5e/dvmf2023-cover.jpg 480w", "/static/1f530db5040b6a49412a85b74e036f6e/1a144/dvmf2023-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1f530db5040b6a49412a85b74e036f6e/1a144/dvmf2023-cover.jpg",
                "alt": "Deer Valley® Music Festival 2023 Cover",
                "title": "Deer Valley® Music Festival 2023 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">DVMF 2023</div>
      </Link>
      <Link to="/dvmf-2022" alt="Link to Deer Valley® Music Festival 2022 publication." mdxType="Link">
        <h3 {...{
          "id": "deer-valley-music-festival--2022",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#deer-valley-music-festival--2022",
            "aria-label": "deer valley music festival  2022 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Deer Valley® Music Festival | 2022`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "152.91666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAMEAgEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/9oADAMBAAIQAxAAAAHz02TYJHiHc7rjhGCn/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEQMSEyEj/9oACAEBAAEFAlx2rCjUB827ach1uXFIgfGBtP/EABgRAAMBAQAAAAAAAAAAAAAAAAABEhEh/9oACAEDAQE/AeYUQiUf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwHSDcKj/8QAGhAAAgMBAQAAAAAAAAAAAAAAABEQITFBEv/aAAgBAQAGPwJoUZk+eThTvhh//8QAHBAAAgMAAwEAAAAAAAAAAAAAAREAITEQUWGB/9oACAEBAAE/IV9n2CKA4KC3YzRABBqHxE1cWLbuGYq0kAB0LLn/2gAMAwEAAgADAAAAEKwBwv/EABcRAQEBAQAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QXJHsCQ//xAAYEQADAQEAAAAAAAAAAAAAAAAAASEQMf/aAAgBAgEBPxBVcN4og//EABwQAQADAQEBAQEAAAAAAAAAAAEAESExUUGR4f/aAAgBAQABPxC4Cm9w9/kJBnXv5BJCJeBQ5rkYwMagjSKyfs89n0KvY+BqGtQugZkZqyWBFDvMjT3Fqqf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/913783f2504fba1a79c414da05e5a148/8ac56/dvmf-2022.webp 240w", "/static/913783f2504fba1a79c414da05e5a148/d3be9/dvmf-2022.webp 480w", "/static/913783f2504fba1a79c414da05e5a148/87ca7/dvmf-2022.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/913783f2504fba1a79c414da05e5a148/09b79/dvmf-2022.jpg 240w", "/static/913783f2504fba1a79c414da05e5a148/7cc5e/dvmf-2022.jpg 480w", "/static/913783f2504fba1a79c414da05e5a148/1a144/dvmf-2022.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/913783f2504fba1a79c414da05e5a148/1a144/dvmf-2022.jpg",
                "alt": "Deer Valley® Music Festival 2022 Cover",
                "title": "Deer Valley® Music Festival 2022 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
        <div className="homeButtons">DVMF 2022</div>
      </Link>
    </section>
    <br />
    <hr />
    <br />
    <OutboundLink href="https://www.octannerjewelers.com//?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=deervalleymusicfestival" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAEDBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHuM41Az22QSD//xAAcEAACAQUBAAAAAAAAAAAAAAAAARECAxASICH/2gAIAQEAAQUCJEN6lx+IipcLH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAIBBQAAAAAAAAAAAAAAAAECEAARICFx/9oACAEBAAY/ApYgX3Qhu5f/xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMRBBUf/aAAgBAQABPyG8tMabamkQe+B3Dce6qaQJgW3M9QMGoa4B5x//2gAMAwEAAgADAAAAEMPODP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQADAAEFAAAAAAAAAAAAAAEAESExEFFhwdH/2gAIAQEAAT8Qb6FNNPEyojy1Euo6xDp7w9S0EF1kNTJ3I/Njwbb5LDdAZZzClGIJpZLS1G+n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/00d6d7a290b1f2e68055cc334a8e8ee6/8ac56/octanner.webp 240w", "/static/00d6d7a290b1f2e68055cc334a8e8ee6/d3be9/octanner.webp 480w", "/static/00d6d7a290b1f2e68055cc334a8e8ee6/87ca7/octanner.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/00d6d7a290b1f2e68055cc334a8e8ee6/09b79/octanner.jpg 240w", "/static/00d6d7a290b1f2e68055cc334a8e8ee6/7cc5e/octanner.jpg 480w", "/static/00d6d7a290b1f2e68055cc334a8e8ee6/1a144/octanner.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/00d6d7a290b1f2e68055cc334a8e8ee6/1a144/octanner.jpg",
              "alt": "OC Tanner",
              "title": "OC Tanner",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      